import Cookies from "js-cookie";
import { BASE_DOMAIN } from "./config";

export const validateForm = (formBody, requiredField) => {
  const fields = { ...formBody };
  let errors = {};
  let formIsValid = true;

  if (requiredField.includes("hotelCategoryID")) {
    if (!fields["hotelCategoryID"]) {
      formIsValid = false;
      errors["hotelCategoryID"] = "Please select hotelCategory";
    }
  }
  if (requiredField.includes("tripTypeID")) {
    if (!fields["tripTypeID"]) {
      formIsValid = false;
      errors["tripTypeID"] = "Please select trip type";
    }
  }
  if (requiredField.includes("transportTypeID")) {
    if (!fields["transportTypeID"]) {
      formIsValid = false;
      errors["transportTypeID"] = "Please select transport type";
    }
  }
  if (requiredField.includes("tripReasonID")) {
    if (!fields["tripReasonID"]) {
      formIsValid = false;
      errors["tripReasonID"] = "Please select trip reason";
    }
  }
  if (requiredField.includes("ticketCategoryID")) {
    if (!fields["ticketCategoryID"]) {
      formIsValid = false;
      errors["ticketCategoryID"] = "Please select ticketCategory";
    }
  }

  if (requiredField.includes("tripTitle")) {
    if (!fields["tripTitle"]) {
      formIsValid = false;
      errors["tripTitle"] = "Please enter trip title";
    }
  }

  if (requiredField.includes("roomCount")) {
    if (!fields["roomCount"]) {
      formIsValid = false;
      errors["roomCount"] = "Please enter room count";
    }
  }

  if (requiredField.includes("passengerCount")) {
    if (!fields["passengerCount"]) {
      formIsValid = false;
      errors["passengerCount"] = "Please enter passenger count";
    }
  }
  if (requiredField.includes("tripStartDate")) {
    if (!fields["tripStartDate"]) {
      formIsValid = false;
      errors["tripStartDate"] = "Please select trip Start Date";
    }
  }

  if (requiredField.includes("tripFrom")) {
    if (!fields["tripFrom"]) {
      formIsValid = false;
      errors["tripFrom"] = "Please select trip from";
    }
  }
  if (requiredField.includes("tripTo")) {
    if (!fields["tripTo"]) {
      formIsValid = false;
      errors["tripTo"] = "Please select trip to";
    }
  }
  if (requiredField.includes("tripEndDate")) {
    if (!fields["tripEndDate"]) {
      formIsValid = false;
      errors["tripEndDate"] = "Select Trip End Date";
    }
  }
  if (requiredField.includes("service_id")) {
    if (!fields["service_id"]) {
      formIsValid = false;
      errors["service_id"] = "Por favor o tipo de serviço";
    }
  }
  if (requiredField.includes("cost_per_service")) {
    if (!fields["cost_per_service"]) {
      formIsValid = false;
      errors["cost_per_service"] = "Por favor, insira Custo por Serviço";
    }
  }
  if (requiredField.includes("cost_per_passenger")) {
    if (!fields["cost_per_passenger"]) {
      formIsValid = false;
      errors["cost_per_passenger"] = "Por favor, insira Custo por passageiro";
    }
  }
  if (requiredField.includes("number_of_passengers")) {
    if (!fields["number_of_passengers"]) {
      formIsValid = false;
      errors["number_of_passengers"] = "Por favor, insira  Quantidade de passageiros";
    }
  }
  if (requiredField.includes("no_of_day_get_visa")) {
    if (!fields["no_of_day_get_visa"]) {
      formIsValid = false;
      errors["no_of_day_get_visa"] = "Por favor, insira o dia para obter o visto";
    }
  }
  if (requiredField.includes("flight_duration")) {
    if (!fields["flight_duration"]) {
      formIsValid = false;
      errors["flight_duration"] = "Por favor, insira o duração do voo";
    }
  }
  if (requiredField.includes("hotel_name")) {
    if (!fields["hotel_name"]) {
      formIsValid = false;
      errors["hotel_name"] = "Por favor, insira o Nome do hotel";
    }
  }
  if (requiredField.includes("hotel_number_of_days")) {
    if (!fields["hotel_number_of_days"]) {
      formIsValid = false;
      errors["hotel_number_of_days"] = "Por favor, insira o Número de dias";
    }
  }
  if (requiredField.includes("hotel_distance")) {
    if (!fields["hotel_distance"]) {
      formIsValid = false;
      errors["hotel_distance"] = "Por favor, insira o distância do hotel";
    }
  }

  if (requiredField.includes("newPassword")) {
    if (!fields["newPassword"]) {
      formIsValid = false;
      errors["newPassword"] = "Por favor, digite a senha";
    }
  }
  if (requiredField.includes("confirmPassword")) {
    if (!fields["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = "Por favor, digite a senha de confirmação";
    }
  }
  if (requiredField.includes("cancellation_policy")) {
    if (!fields["cancellation_policy"]) {
      formIsValid = false;
      errors["cancellation_policy"] = "Por favor, insira Política de Cancelamento";
    }
  }
  if (requiredField.includes("cancellation_policy_validity")) {
    if (!fields["cancellation_policy_validity"]) {
      formIsValid = false;
      errors["cancellation_policy_validity"] = "Por favor, insira Validade da Proposta";
    }
  }
  return {
    isValid: formIsValid,
    errors,
  };
};

export const isValidUser = (request) => {
  if (request?.response?.status === 401 || request?.response?.status === 500) {
    Cookies.remove("G-accessToken", { domain: BASE_DOMAIN });
    return false;
  }
  return true;
};
