import React, { createContext, useContext, useEffect, useState } from 'react';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notificationsCount, setNotificationsCount] = useState(0);

    useEffect(() => {
        const storedNotificationsCount = localStorage.getItem('notificationsCount');
        const initialCount = parseInt(storedNotificationsCount, 10) || 0;
        setNotificationsCount(initialCount);
    }, []);

    useEffect(() => {
        localStorage.setItem('notificationsCount', notificationsCount.toString());
    }, [notificationsCount]);

    return (
        <NotificationContext.Provider value={{ notificationsCount, setNotificationsCount }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
