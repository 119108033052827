import React, { lazy, Suspense, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Spinner from "./components/Spinner";
import { getAPI, PANEL_TYPE, SUPER_ADMIN_URL } from "./utils/config";
import { isValidUser } from "./utils/common";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewQuotation = lazy(() => import("./pages/Quotation/View"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const NewQuotation = lazy(() => import("./pages/Quotation/Create"));
const PageNotFound = lazy(() => import("./components/404"));
const NotificationsLogs = lazy(() => import("./pages/notifications_logs/Log"));
const TripFeedback = lazy(() => import("./pages/trip_feedback/Feedback"));
const Trip = lazy(() => import("./pages/trips/View"));
const PrivateRouteLayout = lazy(() => import("./layout/privateRouteLayout.js"));
const ViewQuotes = lazy(() => import("./pages/quotes/view_quotes"))
const QuoteGroup = lazy(() => import("./pages/quotes/quote_group"))
const QuoteUpdate = lazy(() => import("./pages/quotes/quote_update"))

function App() {
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  useEffect(() => {
    if (!Cookies.get("G-accessToken")) {
      window.location.replace(SUPER_ADMIN_URL);
      return;
    }
    const getDetails = async () => {
      try {
        const decodedToken = jwt_decode(Cookies.get("G-accessToken"));
        if (decodedToken?.ID) {
          const detailInfo = await getAPI({
            url: `user/detail`,
            params: { ID: decodedToken?.ID, serviceType: true },
          });
          if (!isValidUser(detailInfo)) {
            // window.location.href = SUPER_ADMIN_URL
          }
          if (detailInfo?.data?.payload) {
            if (detailInfo?.data?.payload?.role?.UID !== PANEL_TYPE) {
              // window.location.href = SUPER_ADMIN_URL
            }
            setInfo({
              ...detailInfo?.data?.payload,
              cpassword: detailInfo?.data?.payload?.password,
            });
          } else {
          }
        }
      } catch (error) {
        /* window.location.replace(SUPER_ADMIN_URL) */
        // return
      }
    };
    getDetails();
  }, [navigate]);

  return (
    <main className="">
      {/* <Suspense fallback={<Spinner />}> */}
      <Suspense >
        <div className="bg-skin-gray h-screen">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            theme="colored"
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route
              element={
                <PrivateRouteLayout userInfo={info}>
                  <Outlet />
                </PrivateRouteLayout>
              }
            >
              <Route exact path="/dashboard" element={<Dashboard userInfo={info} />} />
              <Route
                exact
                path="/notifications_logs"
                element={<NotificationsLogs />}
              />
              <Route exact path="/trip_feedback" element={<TripFeedback />} />
              <Route exact path="/trips/view" element={<Trip userInfo={info} />} />
              <Route
                exact
                path="/profile/user_profile"
                element={<Profile userInfo={info} />}
              />
              <Route
                exact
                path="/quotations/new_quotation"
                element={<NewQuotation />}
              />
              <Route
                exact
                path="/quotations/view_quotation"
                element={<ViewQuotation />}
              />
            </Route>
            <Route exact path="/quotes/view" element={<ViewQuotes userInfo={info} />} />
            <Route exact path="/quotes/quote_group" element={<QuoteGroup userInfo={info} />} />
            <Route exact path="/quotes/quote_update" element={<QuoteUpdate userInfo={info} />} />

          </Routes>
        </div>
      </Suspense>
    </main>
  );
}
export default App;
